import React, { Component } from "react";
import Footer from "../Footer";
import Menu from "../Menu";
import "./Contact.css";

class Contact extends Component {
  render() {
    return (
      <div className="wrapper">
        <Menu activePage="contact" />
        <div className="items">
          <div className="head">
            <p>Contact</p>
          </div>
          <div className="mail">
            <p>
              {" "}
              Email:
              <a style={{ color: "#007bff" }} href="mailto:dalamo90@gmail.com">
                {" "}
                dalamo90@gmail.com
              </a>
            </p>
          </div>
          <ul className="sites">
            <li style={{ paddingRight: 20 }}>
              <a
                style={{ color: "#007bff" }}
                href="https://www.linkedin.com/in/dalamo20"
              >
                LinkedIn
              </a>
            </li>
            <li style={{ paddingRight: 20 }}>
              <a
                style={{ color: "#007bff" }}
                href="https://github.com/dalamo20"
              >
                Github
              </a>{" "}
            </li>
            <li>
              <a
                style={{ color: "#007bff" }}
                href="https://www.instagram.com/dalamo20/"
              >
                Instagram
              </a>
            </li>
          </ul>
        </div>
        <Footer />
      </div>
    );
  }
}
export default Contact;

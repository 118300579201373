import React, { Component, Fragment } from "react";
import "./Photography.css";
import Menu from "../Menu";
import Footer from "../Footer";
import data from "../../data.json";

class Photography extends Component {
  state = {
    data,
  };

  componentDidMount() {
    this.setState({ data: this.state.data });
  }

  render() {
    return (
      <Fragment>
        <div style={{ margin: "50px" }}>
          <Menu activePage="photography" />
        </div>
        <div className="row">
          <div className="column">
            <img alt="travel" src={data[0].image} style={{ width: "100%" }} />
            <img alt="travel" src={data[1].image} style={{ width: "100%" }} />
            <img alt="travel" src={data[2].image} style={{ width: "100%" }} />
            <img alt="travel" src={data[3].image} style={{ width: "100%" }} />
            <img alt="travel" src={data[4].image} style={{ width: "100%" }} />
            <img alt="travel" src={data[5].image} style={{ width: "100%" }} />
            <img alt="travel" src={data[6].image} style={{ width: "100%" }} />
            <img alt="travel" src={data[7].image} style={{ width: "100%" }} />
            <img alt="travel" src={data[8].image} style={{ width: "100%" }} />
          </div>
          <div className="column">
            <img alt="travel" src={data[9].image} style={{ width: "100%" }} />
            <img alt="travel" src={data[10].image} style={{ width: "100%" }} />
            <img alt="travel" src={data[11].image} style={{ width: "100%" }} />
            <img alt="travel" src={data[12].image} style={{ width: "100%" }} />
            <img alt="travel" src={data[13].image} style={{ width: "100%" }} />
            <img alt="travel" src={data[14].image} style={{ width: "100%" }} />
            <img alt="travel" src={data[15].image} style={{ width: "100%" }} />
            <img alt="travel" src={data[16].image} style={{ width: "100%" }} />
            <img alt="travel" src={data[17].image} style={{ width: "100%" }} />
          </div>
          <div className="column">
            <img alt="travel" src={data[18].image} style={{ width: "100%" }} />
            <img alt="travel" src={data[19].image} style={{ width: "100%" }} />
            <img alt="travel" src={data[20].image} style={{ width: "100%" }} />
            <img alt="travel" src={data[21].image} style={{ width: "100%" }} />
            <img alt="travel" src={data[22].image} style={{ width: "100%" }} />
            <img alt="travel" src={data[23].image} style={{ width: "100%" }} />
          </div>
          <div className="column">
            <img alt="travel" src={data[24].image} style={{ width: "100%" }} />
            <img alt="travel" src={data[25].image} style={{ width: "100%" }} />
            <img alt="travel" src={data[26].image} style={{ width: "100%" }} />
            <img alt="travel" src={data[27].image} style={{ width: "100%" }} />
            <img alt="travel" src={data[28].image} style={{ width: "100%" }} />
          </div>
        </div>
        <Footer />
      </Fragment>
    );
  }
}
export default Photography;

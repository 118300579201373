import React, { Component, Fragment } from "react";
import "./Menu.css";

class Menu extends Component {
  render() {
    const { activePage } = this.props; // Get the activePage prop

    return (
      <Fragment>
        <div className="menu-wrap">
          <input className="toggler" type="checkbox" />
          <div className="hamburger">
            <div></div>
          </div>
          <div className="menu">
            <div>
              <div>
                <ul>
                  <li>
                    <a
                      href="/home"
                      className={activePage === "home" ? "active" : ""}
                    >
                      Home
                    </a>
                  </li>
                  <li>
                    <a
                      href="/about"
                      className={activePage === "about" ? "active" : ""}
                    >
                      About
                    </a>
                  </li>
                  <li>
                    <a
                      href="/contact"
                      className={activePage === "contact" ? "active" : ""}
                    >
                      Contact
                    </a>
                  </li>
                  <li>
                    <a
                      href="/travel"
                      className={activePage === "photography" ? "active" : ""}
                    >
                      Photography
                    </a>
                  </li>
                  <li>
                    <a href="/resume" target="_blank">
                      Resume
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Menu;

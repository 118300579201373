import React, { Fragment } from "react";
import "./Footer.css";

function Footer(){
  return(
    <Fragment>
      <footer>
        <div className="foot-content">
        <div className="leftFooter">
        <h4 id="footSpan"> FOLLOW ME </h4>
        <div className="left-links">
        <a href="https://www.linkedin.com/in/dalamo20" className="linkedin-foot"> LinkedIn </a>
        <a href="https://github.com/dalamo20" className="github-foot"> Github </a>
        </div>
      </div>
      <div className="rightFooter">
        <h4 className="goRight" id="footSpan"> MORE ABOUT ME </h4>
        <div className="right-links">
        <a href="./resume" target="_blank" className="resume-foot"> Resume </a>
        <a href="./contact" className="contact-foot"> Contact </a>
        </div>
      </div>
        </div>
    </footer>
    </Fragment>
  )
} 

export default Footer;

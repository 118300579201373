import React, { Component } from "react";
import "./Home.css";
import Menu from "../Menu";
import Cards from "../Cards";
import Footer from "../Footer";

class Home extends Component {
  render() {
    return (
      <div className="main-container">
        <Menu activePage="home" />
        <div className="container-2">
          <div className="side-1">
            <img src="./img/IMG_2115.jpg" className="image" alt="moi" />
            <a href="/home">
              <img src="./img/logo_white.png" className="logo" alt="logo" />
            </a>
          </div>
          <div className="side-2">
            <div className="inside2">
              <p>
                <span className="fullName">Daniel Alamo</span>
              </p>
              <p className="title">Fullstack Software Developer </p>
              <a href="/about">
                <button className="pill-button">More about me</button>
              </a>
            </div>
          </div>
        </div>
        <Cards />
        <Footer />
      </div>
    );
  }
}

export default Home;

import React, { Component, Fragment } from "react";
import "./App.css";
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import Photography from './components/Photography';
import Pdf from './components/Pdf';

class App extends Component {
  render() {
    return (
      <Fragment>
        <Router>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/home" component={Home} />
            <Route path="/about" component={About} />
            <Route path="/contact" component={Contact} />
            <Route path="/travel" component={Photography} />
            <Route path="/resume" component={Pdf} />
          </Switch>
        </Router>
      </Fragment>
    );
  }
}

export default App;

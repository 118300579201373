import React, { Component, Fragment } from "react";
import { pdfjs, Document, Page } from "react-pdf";
import "./Pdf.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class Pdf extends Component {
  render() {
    return (
      <Fragment>
        <Document
          file="./document/Daniel-resume-08-2023.pdf"
          onLoadSuccess={this.onDocumentLoadSuccess}
        >
          <Page pageNumber={1} />
        </Document>
      </Fragment>
    );
  }
}

export default Pdf;

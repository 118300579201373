import React, { Component } from "react";
import "./About.css";
import Menu from "../Menu";
import Footer from "../Footer";

class About extends Component {
  render() {
    return (
      <div>
        <Menu activePage="about" />
        <div className="logo-container">
          <a href="/home">
            <img src="./img/logo.png" className="logo" alt="logo" />
          </a>
        </div>
        <div className="title-div">
          <p className="title-para">ABOUT ME</p>
        </div>
        <div className="text-container">
          <div className="txt-div">
            <p className="about-para">
              I'm an experienced Software Engineer with a background in
              developing and maintaining full-stack web applications. My
              expertise spans Java and SQL, but it extends to other areas
              including data and front-end technologies such as React.js and
              Handlebars.js. Currently, I'm refining my skills in Android
              development using React.js with TypeScript, a pursuit that aligns
              with my long-standing interests. I'm also currently enrolled in
              Master classes in Software Engineering at Regis University,
              fulfilling a wish that has been on my list for a while.
            </p>
            <p className="about-para">
              During my free time I delve into the realm of Web3. I collect rare
              comic books and first appearance NFT figurines on Marvel and DC
              characters. I am a big fan of horror movies, especially those
              produced by A24. When I have the chance I enjoy traveling across
              the world. Ask me about my trips, I still have more fun pics to
              show and wild stories!
            </p>
            <br />
            <div className="btn-div">
              <a href="/resume" target="_blank">
                <button style={{ width: "138px" }} className="pill-button">
                  Resume
                </button>
              </a>
            </div>
          </div>
          <div className="img-div">
            <img
              style={{ width: "100%" }}
              src="./img/self1.jpg"
              className="image img-fluid"
              alt="moi"
            />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default About;

import React, { Component, Fragment } from "react";
import "./Cards.css";

const cardData = [
  {
    title: "Icebreakers",
    imageSrc: "./img/latte.jpg",
    link: "https://icebreakernu.herokuapp.com/",
    overlayClass: "social",
    overlayColor: "#95613D",
    description: "Social website",
  },
  {
    title: "Catmemory",
    imageSrc: "./img/cat.jpg",
    link: "http://3.12.39.25:3000/",
    overlayClass: "cats",
    overlayColor: "#D59897",
    description: "Memory game",
  },
  {
    title: "Create-OH",
    imageSrc: "./img/liquor.jpg",
    link: "http://createoh.com.s3-website.us-east-2.amazonaws.com",
    overlayClass: "alcohol",
    overlayColor: "#D99D19",
    description: "Alcohol delivery service",
  },
  {
    title: "Travel",
    imageSrc: "./img/DSC05969.jpg",
    link: "/travel",
    overlayClass: "travel",
    overlayColor: "#730411",
    description: "Personal project",
  },
];

class Cards extends Component {
  renderCard(card) {
    const overlayContent =
      card.overlayClass === "travel" ? "PHOTOGRAPHY" : "WEBSITE";

    return (
      <div className="boxed" key={card.title}>
        <a href={card.link}>
          <img
            style={{ width: "100%", display: "block" }}
            src={card.imageSrc}
            className="image"
            alt={card.title}
          />
          <div className={`top-left ${card.overlayClass}`}>
            {overlayContent}
          </div>
          <div className={`overlay ${card.overlayClass}`}>
            <div className="text">
              <p className="top-p">{card.title}</p>
              <p className="lower-p">{card.description}</p>
            </div>
          </div>
        </a>
      </div>
    );
  }

  render() {
    return (
      <Fragment>
        <div className="contained">
          {cardData.map((card) => this.renderCard(card))}
        </div>
      </Fragment>
    );
  }
}

export default Cards;
